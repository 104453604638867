.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.nav_menu {
  display: flex;
  flex-direction: column;
  height: 95%;
  width: 80px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 15px;
  background: var(--Black, #0c0b0b);
  box-shadow: 5px 2px 10px 1px rgb(0 0 0 / 30%);
}

.nav_menu h1 {
  color: white;
}

.top_container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.center_container {
  display: flex;
  flex: 4;
  flex-direction: column;
  justify-content: flex-start;
}

.img_nav {
  margin-block: 10px;
  padding-block: 10px;
  cursor: pointer;
}

.bottom_container {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}