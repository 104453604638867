.table-responsive {
  width: 100%;
  overflow: auto;
  padding-left: 10px;
  padding-right: 10px;
}

table {
  border-collapse: collapse;
}

table thead tr {
  background: #e9edf2;
}

table thead th {
  padding: 15px 5px;
  text-align: center;
  color: #444;
  font-size: 0.9rem;
}

table thead th:first-child {
  padding-left: 1rem;
}

table tbody td {
  padding: 15px 5px;
  color: #444;
  text-align: center;
}

table tbody td:first-child {
  padding-left: 1rem;
  color: var(--main-color);
  font-weight: 600;
  font-size: 0.9rem;
}

table tbody tr {
  border-bottom: 1px solid #dee2e8;
}

.client {
  display: flex;
  align-items: center;
}

.client-info h4 {
  color: #555;
  font-size: 0.95rem;
  margin-block: 0;
}

.client-info small {
  color: #777;
}

.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.actions span {
  display: flex;
  flex-direction: row;
  font-size: 1.5rem;
  margin-right: 0.5rem;
  margin-inline: 15px;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s all;
  border-radius: 50%;
  padding: 0.2rem;

  background-color: transparent;
}

.actions span:hover {
  background-color: #ccc;
  padding: 0.5rem;
}

.paid {
  display: inline-block;
  text-align: center;
  font-weight: 600;
  color: var(--main-color);
  background: #e5f8ed;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  font-size: 0.8rem;
}

@media only screen and (max-width: 1200px) {
  .analytics {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .analytics {
    grid-template-columns: 100%;
  }

  table {
    width: 900px;
  }
}

.add button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-width: 0;
  background: black;
  display: flex;
  color: white;
  font-weight: 500;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 50%;
}

.add button:hover {
  background: #ced1f4;
  color: #0c0b0b;
  transform: scale(1.01);
}

.record-search {
  height: 36px;
  border: 1px solid #b0b0b0;
  border-radius: 3px;
  display: inline-block;
  margin-top: 1px;
  padding: 0rem 0.5rem;
  margin-right: 0.8rem;
  color: #666;
  align-items: end;
}

.table-container {
  width: 100%;
}

.record-header {
  display: flex;
  flex-direction: row;
}

.add {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  display: flex;
  flex: 1;
  justify-content: end;
  align-items: center;
}

.title {
  display: flex;
  flex: 1;
  justify-content: start;
  align-items: center;
  padding-left: 10px;
}

.store-create-input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px 20px;
  grid-template-areas: ". .";
  align-items: center;
}
@media screen and (max-width: 640px) {
  .store-create-input-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 10px 0;
    grid-template-areas:
      "."
      ".";
  }
}

.store-create-button {
  background-color: black;
  margin-top: 10px;
}

@media screen and (max-width: 640px) {
  .record-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .title {
    padding-left: 0;
  }
  .add {
    padding-right: 0;
    padding-top: 5px;
  }
}
