.main_container {
  display: flex;
  flex-direction: row;
  flex: 1;
}

.left_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding-left: 20px;
}

.right_container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.start_root_container {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
}

.home_root_container {
  display: flex;
  flex-direction: column;
}

.home_root_container h3 {
  padding-top: 40px;
  padding-left: 40px;
  margin-block: 0;
}

.home_root_container h6 {
  font-weight: 500;
  padding-left: 40px;
  margin-block: 0;
  font-size: 10px;
}

.home_img_container {
  padding-top: 10px;
  padding-left: 20px;
}
.card-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding-top: 20px;
}

.card {
  background-color: #e1e7e9;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 3px 3px 2px 2px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 640px) {
  .start_root_container {
    padding-left: 0;
  }
  .home_root_container h3 {
    padding: 0;
  }
  .home_root_container h6 {
    padding-left: 0;
  }
  .home_img_container {
    padding-left: 0;
  }
}
