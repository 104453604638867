.input-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 20px 20px;
  grid-template-areas: ". .";
}
@media screen and (max-width: 768px) {
  .input-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0 0;
    grid-template-areas:
      "."
      ".";
  }
}

.input-pair {
  display: flex;
  flex-direction: column; /* Change flex direction to column */
  gap: 10px;
  align-items: center; /* Center align items */
}

.input-pair > .registration-input {
  margin: 0;
}

/* Remove the following code for add, title, and other elements */
.cd-main-container {
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.cd-card {
  flex-direction: column; /* Change flex direction to column */
  align-items: center; /* Center align items */
  -webkit-box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 17px -3px rgba(0, 0, 0, 0.75);
  border-radius: 8px;
  background-color: #fff;
}

.registration-input {
  width: 100%;
}

.registration-input + .registration-input,
.registration-input + .registration-button {
  margin-top: 15px;
}

.registration-button {
  width: 50%;
  background-color: #0c0b0b;
  margin-top: 15px;
}
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.expired {
  background-color: #ffcccb;
}

.inactive {
  background-color: #ffcccb;
}

.active {
  background-color: #90ee90;
}
